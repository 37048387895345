import gql from 'graphql-tag';

const tipos_incidenciaTableQuery = gql`
    query tipos_incidencia($whereConditions:TiposIncidenciaWhereWhereConditions,$numberItems: Int!, $numberPage: Int!, $fieldOrder: String!) {
        tipos_incidencia(where:$whereConditions,first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: DESC}]) {
            data {
                id,
                nombre,
                valor,
                color_id,
                cliente_id,
                empresa_id,
                cliente{
                    nombre
                },
                empresa{
                    nombre
                }
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const queries = { tipos_incidenciaTableQuery };

export default queries;
